import React from "react";
import { apiFetch, toggleProcessing, withStoreAndRouter, userLocation } from "../../utils";
import Button from "./Button";
import _ from "lodash";
import moment from "moment-timezone";
import { VenuesModal, PopupModal, withModal } from "../Modals";
import config from "../../config";

class CartButton extends React.Component<any, any> {
  addToCart = async (isGift: boolean) => {
    const { globals: { tempCart, user: { cart, fetched } }, update, event, updateTempCart } = this.props;

    if(_.find(cart, { id: event.id }))
      return;

    if (fetched) {
      toggleProcessing(true);
      const res = await apiFetch("/cart/add", {
        method: "POST",
        body: JSON.stringify({
          events: [{ videoId: event.id, stripeProductId: event.productId , isGift, giftingEnabled: event.giftingEnabled}]
        })
      });
      if(res.ok) {
        const json = await res.json();
        update(json);
      }
      toggleProcessing(false);
    }
    else {
      updateTempCart([ ...tempCart, {videoId: event.id,stripeProductId: event.productId , isGift, giftingEnabled: event.giftingEnabled} ]);
    }
  }

  viewCart = () => {
    const { globals: { user: { fetched } }, history } = this.props;

    if (fetched) {
      history.push("/cart");
    }
    else {
      history.push("/login", { returnPath: 'cart' });
    }
  }

  getUserLocation = () => {
    userLocation.get(this.props, navigator);
  }

  render() {
    const { event: { id, purchasable, giftingEnabled, purchasableUntil, venues, hasVenues, purchased }, globals: { tempCart, user: { cart, fetched }, geolocated, dynamicStrings } , className} = this.props;
    const currentCart = fetched ? cart : tempCart;
    const findCriteria = fetched ? { id } : { videoId: id };
    const isGift = currentCart.reduce((acc, item) => acc || item.isGift, false);
    const inCart = _.find(currentCart, findCriteria);
    const displayAddToCart = purchasable && !inCart && !isGift && (!hasVenues || (hasVenues && geolocated && venues.length === 0)) && !purchased;
    const displaySendAsGift = giftingEnabled && !inCart && (isGift || currentCart.length === 0) && !moment().isSameOrAfter(moment(purchasableUntil)) && !hasVenues;
    const displayCheckEligibility = !geolocated && hasVenues && !purchased;
    const displayFindTheaters = geolocated && hasVenues && venues?.length > 0 && !purchased;
    
    const EligibilityButton = withModal(Button, PopupModal);
    const FindTheaterButton = withModal(Button, VenuesModal);

    return (
      <React.Fragment>
      { displaySendAsGift && <Button className={`red ${className}`} onClick={this.addToCart.bind(this, true)}>Send as Gift</Button> }
      { inCart && <Button className={`black ${className}`} onClick={this.viewCart}>View Cart</Button> }
      { displayAddToCart && <Button className={`red ${className}`} onClick={this.addToCart.bind(this, false)}>Add to Cart</Button> }
      { displayCheckEligibility 
          && <EligibilityButton 
                className={`red ${className}`} 
                onClick={this.getUserLocation} 
                modalProps={{ 
                  title: "Enable Location",
                  message: dynamicStrings[config.DYNAMIC_STRINGS.ENABLE_LOCATION_MESSAGE]
                }}
              >Check Eligibility</EligibilityButton> 
      }
      { displayFindTheaters 
          && <FindTheaterButton className={`red ${className}`} modalProps={{ event: this.props.event }}>Find A Theater</FindTheaterButton> 
      }
      </React.Fragment>
    )
  }
};
export default withStoreAndRouter()(CartButton);

