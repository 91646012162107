import React from "react";
import PopupModal from "./PopupModal";
import VenuesModal from "./VenuesModal";
import NoStreamingModal from "./NoStreamingModal";

function withModal(WrappedComponent, Modal) {
  return class ModalOpener extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        isOpen: props.isOpen || false
      }
    }

    componentWillUnmount() {
      document.body.style.overflow = 'unset';
    }

    componentDidUpdate() {
      if (this.state.isOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }

    closeModal = () => {
      const { onClose } = this.props;
      this.setState({ isOpen: false });
      onClose && onClose();
    }

    openModal = () => {
      const { onClick } = this.props;
      this.setState({ isOpen: true });
      onClick && onClick();
    }

    confirmModal = (data) => {
      const { onModalConfirm } = this.props;
      this.setState({ isOpen: false });
      onModalConfirm && onModalConfirm(data);
    }

    render() {
      const { modalProps, onModalConfirm, ...props } = this.props;
      const { isOpen } = this.state;

      return (
        <React.Fragment>
          <WrappedComponent {...props} onClick={this.openModal}/>
          {isOpen && <Modal {...modalProps} onClose={this.closeModal}/>}
        </React.Fragment>
      )
    }
  }
};

export {
  withModal,
  PopupModal,
  VenuesModal,
  NoStreamingModal,
};
