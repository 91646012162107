import React from "react";
import Fetch from "../../components/Fetch";
import { Star } from "../../assets";
import Carousel from "../../components/Carousel";
import config from "../../config";
import "./Confirmation.scss";
import { withStoreAndRouter } from "../../utils";

class Confirmation extends React.Component<any, any> {
  onLoad = (data) => {
    const { update } = this.props;
    if(data) {
      const { cart, events, order: { items, number } } = data;
      update({ cart, events });
      
      items.forEach(({ name, price }) => {
        window.dataLayer.push({
          'event': 'purchase',
          'revenue': price[0].price,
          'orderId': number,
          'currency': price[0].currency,
          'quantity': 1,
          'showName': name
        });
      });
    }
  }

  render() {
    const { globals: { components } } = this.props;
    return (
      <div className="Confirmation">
        <Fetch url={`${config.PPV_API}/order${window.location.pathname}`} includeCredentials onComplete={this.onLoad}>
          {(loading, errors, data) => {
            const order = data && data.order;
            //const related = data && data.related;

            return (
              (loading || !data) ? null :
              <React.Fragment>
                <img alt="star" src={Star} />
                <div className="header">Thank you for your order.</div>
                <div className="message">{`An order confirmation email has been sent to ${order.email}. The email contains link(s) to the events you purchased.`}</div>
                <div className="message">Please call 212-362-6000 or e-mail us at <a href="mailto:metropolitanopera@metopera.org">MetropolitanOpera@metopera.org</a> for assistance.</div>
                {/*RELATED/YOU MIGHT ALSO LIKE SECTION - Removed as requested
                 { related.items.length > 0 && <Carousel className="related" {...related} /> } */}
                {components && components.exploreTheMet && <Carousel className="explore" {...components.exploreTheMet} />}
              </React.Fragment>
            )
          }}
        </Fetch>
      </div>
    )
  }
};

export default withStoreAndRouter()(Confirmation);
