import React from "react";
import Button from "../Button";
import { ExternalLink } from "../HtmlElements";
import "./Venue.scss";

export default class Venue extends React.Component<any, any> {
  render() {
    const { venue: { name, address, city, distance, url } } = this.props;
    const roundedDistance = Math.round(distance * 10) / 10;

    return (
      <div className="Venue">
        <div className="Venue-info">
          <p className="Venue-name">{name}</p>
          <p className="Venue-distance">{`${roundedDistance} Mile${(roundedDistance === 0 || roundedDistance === 1) ? "" : "s"} away`}</p>
        </div>
        <p className="Venue-address">{address}</p>
        <p className="Venue-address">{city}</p>
        <ExternalLink href={url.startsWith("http") ? url : `//${url}`}><Button className="red">Buy Tickets</Button></ExternalLink>
      </div>
    );
  }
};
