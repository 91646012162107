import React from "react";
import classnames from "classnames";
import "./CheckoutProgress.scss";

const CheckoutProgress: React.FunctionComponent<any> = ({ step }) => {
  return (
    <div className="CheckoutProgress">
      <div className={classnames("bar", { selected: step >= 2})}/>
      <div className={classnames("bar", { selected: step >= 3})} />

      <div className={classnames("step", "one", { selected: step >= 1})}>
        <div className="oval" />
        <div className="label">Review Cart</div>
      </div>

      <div className={classnames("step", "two", { selected: step >= 2})}>
        <div className="oval" />
        <div className="label">Provide Payment</div>
      </div>

      <div className={classnames("step", "three", { selected: step >= 3})}>
        <div className="oval" />
        <div className="label">Complete Purchase</div>
      </div>
    </div>
  )
};

export default CheckoutProgress;
