import { createStore as createReduxStore } from "redux";
import { StoreState, GenericAction, storageKey, StorageField} from "./types";
import { reducer, defaultState, initLocalStorage } from "./reducers";
import withStore from "./mappers";

export default function createStore(initialState = {}) {
  let state = { ...defaultState, ...initialState };
  initLocalStorage(state);
  return createReduxStore<StoreState, GenericAction, any, any>(reducer, state);
};

export { withStore, storageKey, StorageField};
export type { StoreState };
