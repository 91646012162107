import React from "react";
import { withStoreAndRouter } from "../../utils";
import "./FullContentMessage.scss";
import classnames from "classnames";

class FullContentMessage extends React.Component<any, any> {

  render() {
    const { title, body, className } = this.props;
    return (
      <div className={classnames("FullContentMessage", className)}>
        <span className="FullContentMessage-title" dangerouslySetInnerHTML={{ __html: title }}></span>
        <div className="FullContentMessage-body" dangerouslySetInnerHTML={{ __html: body }}></div>
      </div>
    )
  }
};
export default withStoreAndRouter()(FullContentMessage);
