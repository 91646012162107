import React from 'react';
import { Provider } from "react-redux";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Content from "./components/Content";
import Home from "./routes/Home";
import Cart from "./routes/Cart";
import Checkout from "./routes/Checkout";
import Confirmation from "./routes/Confirmation";
import EventPage from "./routes/EventPage";
import HowItWorks from "./routes/HowItWorks";
import Login from "./routes/Login";
import createStore from "./store";
import { BrowserRouter as Router, Switch, Route, matchPath } from "react-router-dom";
import './App.scss';
import EmailPage from './routes/EmailPage';

const store = createStore();

export default class App extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      sessionLoaded: false,
    }
  };

  componentDidMount() {
    // Close the mobile menu if the user clicks outside of it
		window.onclick = function(event) {
      if (!event.target.matches(".nav-humburger-button > svg")) {
        let mobileMenu = document.getElementById("mobile-menu");
        if (mobileMenu && mobileMenu.style.display === "block") {
          mobileMenu.style.display = "none";
        }
			}
		};
  }

  onSessionLoaded = () => {
    this.setState({ sessionLoaded: true })
  }

  isEmailRoute = () => {
    return !!matchPath(window.location.pathname, {
      path: "/emails/:emailType/:orderId",
      exact: true
    });
  }

  render() {
    const { sessionLoaded } = this.state;
    return (
      <div className="App">
        <Router>
          <Provider store={store}>
          { this.isEmailRoute()
            ? <Route path="/emails/:emailType/:orderId" exact component={EmailPage} />
            : <>
                <Header onLoad={this.onSessionLoaded} />
                { sessionLoaded &&
                  <Content>
                    <Switch>
                      <Route path="/" exact render={() => <Home />} />
                      <Route path="/cart" exact component={Cart} />
                      <Route path="/checkout" exact component={Checkout} />
                      <Route path="/confirmation/:orderId" exact component={Confirmation} />
                      <Route path="/events/:eventId" exact component={EventPage} />
                      <Route path="/how-it-works" exact render={() => <HowItWorks />} />
                      <Route path="/login" exact render={() => <Login  onLoad={this.onSessionLoaded} /> }  />
                    </Switch>
                  </Content>
                }
                <Footer />
              </>
          }
          </Provider>
        </Router>
      </div>
    );
  }
};
