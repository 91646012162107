import React from "react";
import Button from "../Button";
import "./NoStreamingModal.scss";
import { withModal, VenuesModal } from "../Modals";
import config from "../../config"


export default class NoStreamingModal extends React.Component<any, any> {
  render() {
    const { title, message, onClose, venues } = this.props;

    const FindTheaterButton = withModal(Button, VenuesModal);
    return (
      <div className="NoStreamingModal-modal">
        <div className="NoStreamingModal-modal-content">
          <span className="NoStreamingModal-modal-title"  dangerouslySetInnerHTML={{ __html: title }}/>
          <span className="NoStreamingModal-modal-message" dangerouslySetInnerHTML={{ __html: message }}/>
          <div className="NoStreamingModal-modal-actions">
            <Button className="medium" onClick={onClose}>OK</Button>
            <FindTheaterButton
              className={`red medium`}
              modalProps = {{
                title: "a title",
                message: "a message",
                event: {venues}
              }}
            >Find A Theater</FindTheaterButton>
          </div>
        </div>
      </div>
    );
  }
};
