export interface StoreState {
  user: User,
  tempCart: any[],
  components: any,
  dynamicStrings: any,
  geolocated: boolean,
};

export interface User {
  userId?: string,
  name: string,
  email: string,
  events: any[],
  giftEvents: any[],
  cart: any[],
  fetched: boolean,
  fetching: boolean,
}

export interface StoreProps {
  globals: StoreState
  update(user: any): void
};

export interface GenericAction {
  type: string
  data?: any
};

export enum StorageField {
  LoggedIn = "LoggedIn",
  TempCart = "TempCart"
};

export function storageKey(field) {
  return `MetatHome.${field}`
}

declare global {
  interface Window {
    dataLayer: any;
  }
}