import { UPDATE, SET_USER, FETCH_USER, HANDLE_LOGOUT, UPDATE_TEMP_CART, SET_COMPONENTS, SET_DYNAMIC_STRINGS, SET_GEOLOCATION_STATUS } from "../actions";
import { StoreState, GenericAction, StorageField, storageKey } from "../types";
import { parseCartResponse } from "../../utils";

function updateLocalStorage(key: StorageField, value: any) {
  localStorage.setItem(storageKey(key), typeof value == "object" ? JSON.stringify(value) : value);
}

export function initLocalStorage(values) {
  for(let field in StorageField) {
    let key = field.toLowerCase();
    let keyInStorage = storageKey(field);
    let valueInStorage = localStorage.getItem(keyInStorage);

    if(key in values && !valueInStorage) {
      updateLocalStorage(StorageField[StorageField[field]], values[key]);
    }
  }
}

const defaultUser = {
  userId: undefined,
  name: "",
  email: "",
  events: [],
  giftEvents: [],
  cart: [],
  fetched: false,
  fetching: false,
}

export const defaultState = {
  user: defaultUser,
  tempCart: [],
  components: undefined,
  dynamicStrings: {},
  geolocated: false,
};

function updateUser(state, userData) {
  if (userData.cart) {
    userData.cart = parseCartResponse(userData.cart);
  }
  return {
    ...state,
    user: {
      ...state.user,
      ...userData
    }
  };
}

export function reducer(state: StoreState = defaultState, action: GenericAction): StoreState {
  switch (action.type) {

    case UPDATE:
      return {...updateUser(state, action.data)};

    case SET_USER:
      updateLocalStorage(StorageField.LoggedIn, true);
      return {...updateUser(state, { ...action.data, fetched: true, fetching: false })};

    case FETCH_USER:
      return {...updateUser(state, { fetching: true })};

    case HANDLE_LOGOUT:
      updateLocalStorage(StorageField.LoggedIn, false);
      window.location.reload();
      return { ...state, user: defaultUser };

    case UPDATE_TEMP_CART:
      updateLocalStorage(StorageField.TempCart, [...action.data]);
      return { ...state, tempCart: [...action.data] };

    case SET_COMPONENTS:
      return { ...state, components: {...action.data} };

    case SET_DYNAMIC_STRINGS:
      return { ...state, dynamicStrings: {...action.data} }

    case SET_GEOLOCATION_STATUS:
      return { ...state, geolocated: true };

    default:
      return state;
  }
};
