import React from "react";
import Fetch from "../../components/Fetch";
import Event from "../../components/Event";
import Hero from "../../components/Hero";
import FullContentMessage from "../../components/FullContentMessage";
import "./Home.scss";
import config from "../../config"
import moment from "moment-timezone";
import Carousel from "../../components/Carousel";
import { withStoreAndRouter, userLocation, getLocalStorageValue, setLocalStorageValue } from "../../utils";
import { NoStreamingModal, withModal } from "../../components/Modals";

class Home extends React.Component<any, any> {
  shouldComponentUpdate(nextProps) {
    if (
      this.props.globals.user.cart.length !== nextProps.globals.user.cart.length
      || this.props.globals.tempCart !== nextProps.globals.tempCart
    ) {
      return false;
    }
    return true;
  }

  render() {
    const { globals: { components, dynamicStrings, geolocated } } = this.props;
    const lat = getLocalStorageValue("latitude");
    const long = getLocalStorageValue("longitude")
    const userCoords = (lat && long) ? { lat, long } : null;
    
    return (
      <div className="Home">
        <Fetch url={`${config.PPV_API}/events`} query={userCoords} includeCredentials>
          {(loading, errors, data) => {
            let canStream = false

            const Modal = withModal(()=> (<div/>), NoStreamingModal);
            //These lines are to filter the not-purchasable events from the home event list.
            // const now = moment();
            // const events = data && data.events.filter(event => event.purchasableUntil && moment(event.purchasableUntil).isAfter(now));
            let availableVenues = JSON.parse(sessionStorage.getItem("availableVenues") as any)
            if (!availableVenues) {
              availableVenues = {
                ids: [],
                venues: {}
              }
            }
            const geoRestricted = data && data.geoRestricted,
              events = (
                !geoRestricted
                && data
                && data.events.map((el) => {
                                if (!el.venues.length) {
                                  canStream = true
                                }
                                el.venues.map((v) => {
                                  if (v.venue_id && !availableVenues.venues[v.venue_id]) {
                                    availableVenues.venues[v.venue_id] = v
                                    availableVenues.ids.push(v.venue_id)
                                  }
                                })
                                return { ...el, giftingEnabled: !!el.giftingEnabled }
                              })
                              .sort((event1, event2) => {
                                if (event1.eventDate === event2.eventDate) {
                                  let eventLanguage1 = event1.language?.toLowerCase();
                                  let eventLanguage2 = event2.language?.toLowerCase();
                                  if (!eventLanguage1 || eventLanguage1.includes("english")) {
                                    eventLanguage1 = "english"
                                  }
                                  if (!eventLanguage2 || eventLanguage2.includes("english")) {
                                    eventLanguage2 = "english"
                                  }
                                  const languageWeight = {
                                    "english": 1,
                                    "spanish": 2,
                                    "french": 3,
                                    "italian": 4,
                                    "portuguese": 5,
                                  }
                                  if (eventLanguage1 !== eventLanguage2) {
                                    if (!languageWeight[eventLanguage1] || (languageWeight[eventLanguage1] > languageWeight[eventLanguage2])) return 1
                                    if (!languageWeight[eventLanguage2] || (languageWeight[eventLanguage2] > languageWeight[eventLanguage1])) return -1
                                  }
                                }
                                return 0;
                              })
              ),
              now = moment(),
              availableEvents = events && events.filter(event => event.purchasableUntil && moment(event.purchasableUntil).isAfter(now)).length > 0,
              haveVenues = availableEvents && events.find((event) => event.hasVenues);

            if (haveVenues && !geolocated) {
              userLocation.get(this.props, navigator);
            }


            return (
              (loading || !components || !data) ? null :
              <React.Fragment>
                {geoRestricted &&
                <React.Fragment>
                  <FullContentMessage className="geo" title={dynamicStrings[config.DYNAMIC_STRINGS.GEO_RESTRICTION_TITLE]} body={dynamicStrings[config.DYNAMIC_STRINGS.GEO_RESTRICTION_MESSAGE]} />
                  {components.exploreTheMet && <div className="carousel-container"><Carousel className="explore" {...components.exploreTheMet} /></div>}
                </React.Fragment>
                }
                {!geoRestricted &&
                  <React.Fragment>
                    <div className="events-container">
                      <Hero events={events} />
                      <div className="events">
                        { availableEvents ?
                          events.map(event => <Event key={event.id} event={event} /> ) :
                          <FullContentMessage
                            className="no-events"
                            title={dynamicStrings[config.DYNAMIC_STRINGS.NO_EVENTS_TITLE]}
                            body={dynamicStrings[config.DYNAMIC_STRINGS.NO_EVENTS_MESSAGE]}
                          />
                        }
                      </div>
                      <Modal
                        isOpen={(userCoords && !canStream && !getLocalStorageValue("noStreaming"))}
                        onClose={() => setLocalStorageValue("noStreaming", true)}
                        modalProps={{
                          title: dynamicStrings[config.DYNAMIC_STRINGS.NO_STREAMING_AVAILABLE_TITLE],
                          message: dynamicStrings[config.DYNAMIC_STRINGS.NO_STREAMING_AVAILABLE_MESSAGE],
                          venues: availableVenues.ids.map((id) => availableVenues.venues[id]),
                        }}
                      />
                      {components.sponsors && components.sponsors.items &&
                        components.sponsors.items.map((el) => {
                          return el.tile && <img key={el.tile.id} alt="donors" className="donors" src={el.tile.image.url} />
                        })
                      }
                    </div>
                    {components.exploreTheMet && <div className="carousel-container"><Carousel className="explore" {...components.exploreTheMet} /></div>}
                  </React.Fragment>
                }
              </React.Fragment>
            )
          }}
        </Fetch>
      </div>
    )
  }
};

export default withStoreAndRouter()(Home);
