import React from "react";
import { withRouter } from "react-router";
import "./Overlay.scss";

const Overlay: React.FunctionComponent<any> = ({ text }) => {

  return (
    <div className="Overlay">
      <p className="text">{text}</p>
    </div>
  )
};

export default withRouter(Overlay);
