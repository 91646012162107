import React, { useState } from "react";
import { CartButton } from "../Button";
import moment from 'moment-timezone';
import { InputDateFormat, UpcomingDateFormat, AvailableDateFormat, withStoreAndRouter, getDateLabel } from "../../utils";
import config from "../../config";
import Overlay from "../Overlay"
import Player from "../Player"
import { PlayButton } from "../../assets";
import "./EventDescription.scss";
import Price from "../Price";

const EventDescription: React.FunctionComponent<any> = ({ event, globals: { geolocated }, ...props }) => {

  const {
    id,
    poster,
    name,
    subHeader,
    secondaryTitle,
    body,
    purchased,
    status,
    eventDate,
    purchasableUntil,
    estimatedRuntime,
    purchasable,
    price,
    venues,
    hasVenues,
  } = event;
  const [SourceErrorOverlay, setSourceErrorOverlay] = useState(false)
  const expirationDate = moment(purchasableUntil);
  const eventDateTZ = moment(eventDate).subtract(config.PRE_AVAILABILITY_TIME, 'minutes');
  let isAfterConcert = false;
  const isLive = status === 'LIVE' || status === 'PRE';
  const displayPrice = purchasable && !purchased && (!hasVenues || (hasVenues && geolocated && venues.length === 0));

  const IsRuntime = () => {
    return isLive && estimatedRuntime && moment().isBetween(eventDateTZ, moment(eventDate).add(estimatedRuntime, 'seconds'))
  }

  const AvailabilityText = () => {

    let availabilityElement = <p></p>;

    if (expirationDate.isBefore(moment())) {
      availabilityElement = <p className="event-live-date-title">This event is No Longer Available</p>
    } else {

      if (!eventDate) return <p className="event-live-date-title">DATE TO BE ANNOUNCED</p>;
      if (status === 'VOD' || status === 'ENCORE') return <p className="event-live-date-title">{getDateLabel(eventDate, purchasableUntil, estimatedRuntime, status, purchased)}</p>;

      const formattedEventDate = moment(eventDate, InputDateFormat);
      const dateLabel = `Live: ${formattedEventDate.format(UpcomingDateFormat).replace(":00 ", "")}`;

      if (eventDateTZ.isAfter(moment())) {
        availabilityElement = <p className="event-live-date-title">{dateLabel}</p>
      } else if (!isAfterConcert && IsRuntime()) { //During concert
        availabilityElement = <p className="event-live-date-title">Live Now</p>
      } else if(isAfterConcert || !IsRuntime()) { //After concert
        availabilityElement = <p className="event-live-date-title">Available Until {expirationDate.tz(config.MOMENT_TIMEZONE, true).format(AvailableDateFormat)}</p>
      }
    }

    return availabilityElement;
  }

  const UpcomingOverlay = () => {
    const formattedEventDate = moment(eventDate, InputDateFormat);
    return (
      <div>
      {purchased && isLive && <div className="player-area-overlay"><Overlay text={`This event will be live on ${formattedEventDate.format(UpcomingDateFormat).replace(":00 ", "")}`} /><div className='fake-play-button'><PlayButton /></div></div>}
      {purchased && status === 'ENCORE' && <div className="player-area-overlay"><Overlay text={`This event will be available on ${formattedEventDate.format(UpcomingDateFormat).replace(":00 ", "")}`} /><div className='fake-play-button'><PlayButton /></div></div>}
    </div>
    )
  }

  const OverlayElement = (props) => {
    let overlayElement = <div></div>;
    const isVODSoon = props.SourceErrorOverlay
    if (expirationDate.isAfter(moment())) {
      if (!event.eventDate) {
        overlayElement = (
          <div>
            <Overlay text='DATE TO BE ANNOUNCED' />
          </div>
        );
        return overlayElement;
      }
      if (status === 'VOD')
        if (isVODSoon){
          overlayElement = (
            <div>
              <Overlay text='This live event has ended and will be available for playback soon' />
            </div>
          );
        } else {
          return overlayElement;
        }

      if (status === 'ENCORE') {
        if (moment(eventDate).isAfter(moment())) {
          overlayElement = (
            <div>
              <UpcomingOverlay />
            </div>
          );
        }
        return overlayElement;
      }
      if (eventDateTZ.isAfter(moment())) {
        overlayElement = (
          <div>
            <UpcomingOverlay />
          </div>
        );
      } else if (!isAfterConcert && IsRuntime()) { //During concert
        overlayElement = <div></div>;
      } else { //After concert
        if (isAfterConcert || !IsRuntime() || status === 'PROCESSING') {
          overlayElement = (
            <div>
              <Overlay text='This live event has ended and will be available for playback soon' />
            </div>
          );
        }
      }
    }

    return overlayElement;
  }

  const PlayerArea = () => {
    if (expirationDate.isBefore(moment())) {
      return getPlayerArea(purchased, false);
    } else {
      if (status === 'VOD' || (status === 'ENCORE' && eventDate && moment(eventDate).isBefore(moment()))) {
        return getPlayerArea(purchased, true);
      } else if (!event.eventDate || (isLive && eventDateTZ.isAfter(moment()))) {
        return getPlayerArea(purchased, false);
      } else if (!isAfterConcert && IsRuntime()) { //During concert
        return getPlayerArea(purchased, true);
      }
    }
    return getPlayerArea(purchased, false);
  }

  const isCatalogError = (isError) => {
    if (isError) {
      isAfterConcert = isError;
    }
  }

  const isSourceError = (isError) => {
    if (isError) {
      console.log("BLKJDFLKSJDFLkj")
      setSourceErrorOverlay(true)
    }
  }

  const onWatchSelected = () => {
    const poster: HTMLElement = document.querySelector('video-js .vjs-poster') as HTMLElement;
    if (poster)
      poster.click();
    const startOverlay: HTMLElement = document.querySelector('.start-overlay') as HTMLElement;
    startOverlay.remove();
  }

  const onStartOverlayClick = () => {
    props.showStartPopup(onWatchSelected);
  }

  const getPlayerArea = (purchased, isPlayable) => {
    if (!isPlayable || SourceErrorOverlay) {
      return (
        <div className="player-area">
          <img alt="event" src={poster} />
          <OverlayElement SourceErrorOverlay />
        </div>
      );
    } else {
      if (!purchased) {
        return (
          <div className="player-area">
            <img alt="event" src={poster} />
          </div>
        );
      } else {
        return (
          <div className="player-area">
            {props.showPopupOnEventStart && <div className='start-overlay' onClick={onStartOverlayClick}></div>}
            <Player videoId={id} className='player-container' onSuccess={() => {setSourceErrorOverlay(false)}} onCatalogError={isCatalogError}  onSourceError={isSourceError}/>
          </div>
        );
      }
    }
  }

  const CartButtons = () => {
    if (expirationDate.isBefore(moment())) {
      return <div></div>;
    } else {
      if (!purchased) {
        return <div className="actions">
          <CartButton event={event} className={"large"} />
        </div>;
      } else {
        return <div></div>;
      }
    }
  }

  return (
    <React.Fragment>
      <PlayerArea />
      <div className="event-area">
        <div className="text-area">
          <p className="event-name-title">{name}</p>
          <p className="event-name-subtitle">{subHeader}</p>
          <p className="event-secondary-title">{secondaryTitle}</p>
          <AvailabilityText />
          { displayPrice && <Price price={price && price.price} currency={price && price.currency} /> }
          <CartButtons />
          <div className="event-description">
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withStoreAndRouter()(EventDescription);
