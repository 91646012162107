import { update, setUser, fetchUser, handleLogout, updateTempCart, setComponents, setDynamicStrings, setGeolocationStatus } from "./actions";
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return { globals: state };
};

function mapDispatchToProps(dispatch) {
  return {
    update: (user) => dispatch(update(user)),
    setUser: (user) => dispatch(setUser(user)),
    fetchUser: () => dispatch(fetchUser()),
    handleLogout: () => dispatch(handleLogout()),
    updateTempCart: (tempCart) => dispatch(updateTempCart(tempCart)),
    setComponents: (components) => dispatch(setComponents(components)),
    setDynamicStrings: (dynamicStrings) => dispatch(setDynamicStrings(dynamicStrings)),
    setGeolocationStatus: () => dispatch(setGeolocationStatus()),
  };
};

// Helper functions
const withStore = connect(mapStateToProps, mapDispatchToProps);

export default withStore;