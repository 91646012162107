import React from "react";
import Button, { CartButton } from "../../Button";
import { withRouter } from "react-router";
import { getDateLabel } from "../../../utils";
import "./Tile.scss";

const Tile: React.FunctionComponent<any> = ({ item, history }) => {
  const {
    id,
    thumbnail,
    name,
    purchased,
    eventDate: rawEventDate,
    purchasableUntil: rawPurchasableUntil,
    estimatedRuntime,
    status,
  } = item;
  const url = item.url || `/events/${id}`;
  const date = getDateLabel(rawEventDate, rawPurchasableUntil, estimatedRuntime, status, purchased);

  return (
    <div className="Tile">
      <img alt="tile" src={thumbnail} />
      <div className="metadata">
        <div className="name">{name}</div>
        <div className="date">{date}</div>
        <div className="actions">
        <React.Fragment>
          <CartButton event={item} className={"large"} />
          { !purchased && <Button white onClick={() => history.push(url)}>Learn More</Button> }
        </React.Fragment>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Tile);
