import React from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import "./Price.scss";

export default class Price extends React.Component<any, any> {
  render() {
    const { price, currency } = this.props;
    
    return (
      <div className="Price">
        {currency && getSymbolFromCurrency(currency)}{price && price.toFixed(2)}
      </div>
    );
  }
};
