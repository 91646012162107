import React from "react";
import Button from "../../Button";
import { withRouter } from "react-router";
import { ExternalLink } from "../../HtmlElements";
import "./Tile.scss";

const Tile: React.FunctionComponent<any> = ({ item, metadata, history }) => {
  const {
    ctaLabel,
    description,
    header,
    image,
  } = item;

  const url = metadata && JSON.parse(metadata).url;

  return (
    <div className="Tile non-event">
      <img alt="tile" src={image.url} />
      <div className="metadata">
        <div className="name">{header}</div>
        <div className="description" dangerouslySetInnerHTML={{__html: description}}></div>
        <div className="actions">
          <ExternalLink href={url}><Button white>{ctaLabel ? ctaLabel : "Learn More"}</Button></ExternalLink>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Tile);
