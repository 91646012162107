import React from "react";
import Fetch from "../../components/Fetch";
import config from "../../config";

export default class EmailPage extends React.Component<any, any> {
  render() {
    const { match } = this.props;
    return (
      <Fetch url={`${config.EMAIL_API}/${match.params.emailType}/${match.params.orderId}`}>
        {(loading, errors, data) => {
          return (
            <div dangerouslySetInnerHTML={{__html: data}}></div>
          );
        }}
      </Fetch>
    )
  }
};
