export const UPDATE = "UPDATE";
export const SET_USER = "SET_USER";
export const FETCH_USER = "FETCH_USER";
export const HANDLE_LOGOUT = "HANDLE_LOGOUT";
export const UPDATE_TEMP_CART = "UPDATE_TEMP_CART";
export const SET_COMPONENTS = "SET_COMPONENTS";
export const SET_DYNAMIC_STRINGS = "SET_DYNAMIC_STRINGS";
export const SET_GEOLOCATION_STATUS = "SET_GEOLOCATION_STATUS";

export function update(user: any) {
  return {
    type: UPDATE,
    data: user
  }
};

export function setUser(user) {
  return {
    type: SET_USER,
    data: user
  }
}

export function fetchUser() {
  return {
    type: FETCH_USER,
  }
}

export function handleLogout() {
  return {
    type: HANDLE_LOGOUT,
  }
}

export function updateTempCart(tempCart) {
  return {
    type: UPDATE_TEMP_CART,
    data: tempCart
  }
}

export function setComponents(components) {
  return {
    type: SET_COMPONENTS,
    data: components,
  }
}

export function setDynamicStrings(dynamicStrings) {
  return {
    type: SET_DYNAMIC_STRINGS,
    data: dynamicStrings
  }
}

export function setGeolocationStatus() {
  return {
    type: SET_GEOLOCATION_STATUS,
  }
}
