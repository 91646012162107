import React from "react";
import { withRouter } from "react-router";
import "./Content.scss";

class Content extends React.Component<any, any> {
  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return <div className="content">{children}</div>
  }
};

export default withRouter(Content);
