import React from "react";
import Button from "./Button";
import { apiFetch, toggleProcessing, withStoreAndRouter } from "../../utils";
import _ from "lodash";

class AddAllButton extends React.Component<any, any> {
  addAll = async () => {
    const { events: eventsArr, globals: {user: { cart, events: userEvents }}, history, update } = this.props;

    const events = eventsArr
      .filter(event => event.purchasable && !(_.find(cart, { id: event.id }) || _.find(userEvents, { id: event.id })))
      .map(event => ({ videoId: event.id, stripeProductId: event.productId, giftingEnabled: event.giftingEnabled }));

    toggleProcessing(true);
    const res = await apiFetch("/cart/add", {
      method: "POST",
      body: JSON.stringify({ events })
    });
    if(res.ok) {
      const json = await res.json();
      update(json);
      setTimeout(() => history.push("/cart"), 150);
    }
    toggleProcessing(false);
  }

  render() {
    const { events, globals: { user: { cart, events: userEvents } } } = this.props;
    const show = events.filter(event => (
      !(_.find(cart, { id: event.id }) || _.find(userEvents, { id: event.id }))
    )).length > 0;

    return (show ? <Button className="add_all_button red large" onClick={this.addAll}>Add All To Cart</Button> : null )
  }
};
export default withStoreAndRouter()(AddAllButton);
