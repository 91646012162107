import React from "react";
import { NavLink } from "react-router-dom";
import { withStoreAndRouter, fetchHandler } from "../../utils";
import { Logout, Account } from "../../assets/index";
import "./LoginStatus.scss";
import config from "../../config";

class LoginStatus extends React.Component<any, any> {

  onLogout = () => {
    fetchHandler(`${config.CLIENT_API}/logout`, {}, this.handleLogout, null);
  }

  handleLogout = () => {
    const { history } = this.props;
    if (config.AUTH_REQUIRED_ROUTES.findIndex(el => el === history.location.pathname) !== -1) {
      history.push("/login");
    }
    this.props.handleLogout();
  }

  render() {
    const { globals: { user } } = this.props;
    return (
      <div className="login-status-container">
        {((!user.fetching && !user.fetched ) || !user.userId) && <NavLink activeClassName="selected" exact to="/login">login / register</NavLink>}
        {!user.fetching && user.fetched &&
          <React.Fragment>
            <div className="login-status-user"><Account />{user.name}</div>
            <div className="login-status-menu">
              <span className="login-status-logout" onClick={this.onLogout}><Logout />log out</span>
            </div>
          </React.Fragment>
        }
      </div>
    )
  }
};
export default withStoreAndRouter()(LoginStatus);
