import React from "react";
import { getDateLabel } from "../../utils";
import { ExternalLink } from "../HtmlElements";
import Venue from "../Venue";
import "./VenuesModal.scss";

export default class VenuesModal extends React.Component<any, any> {
  render() {
    const { event: { name, purchasableUntil, eventDate, estimatedRuntime, status, venues, subHeader }, onClose } = this.props;
    const url = "https://www.metopera.org/season/in-cinemas";
    const sortedVenues = venues.sort((venue1, venue2) => venue1.distance - venue2.distance).slice(0, 50);

    return (
      <div className="Venues-modal">
        <div className="Venues-modal-content">
          <header className="Venues-modal-header">
            <div aria-label="close modal" role="button" tabIndex={0} onClick={onClose}>
              <span></span>
              <span></span>
            </div>
            <h2 className="Venues-modal-title">{name || ""}</h2>
            <p className="Venues-modal-subtitle">{subHeader || ""}</p>
            <p className="Venues-modal-date">{eventDate && getDateLabel(eventDate, purchasableUntil, estimatedRuntime, status)}</p>
          </header>
          <ul className="Venues-modal-items">
            { sortedVenues.map((venue) => (
              <li key={venue.venue_id} className="Venues-modal-item">
                <Venue venue={venue} />
                <hr />
              </li>
            ))}
          </ul>
          <footer className="Venues-modal-footer">
            <p>For a complete list of cinemas for the Live in HD series, go to <ExternalLink href={url}>metopera.org/season/in-cinemas</ExternalLink></p>
          </footer>
        </div>
      </div>
    );
  }
};
