import React from "react";
import Button, { CartButton } from "../Button";
import { getDateLabel, getLocalStorageValue, withStoreAndRouter } from "../../utils";
import { withRouter } from "react-router";
import "./Event.scss";
import Price from "../Price";

const Event: React.FunctionComponent<any> = ({ event, globals: { geolocated }, history }) => {
  const {
    id,
    thumbnail,
    name,
    secondaryTitle,
    description,
    purchased,
    eventDate: rawEventDate,
    purchasableUntil: rawPurchasableUntil,
    availableNow,
    comingSoon,
    estimatedRuntime,
    status,
    purchasable,
    price,
    venues,
    hasVenues,
  } = event;

  const availability = availableNow ? "Available Now" :
  comingSoon ? "Coming Soon" :
    null;
  const isLoggedIn = getLocalStorageValue("LoggedIn") === "true" ? true : false;
  const displayPrice = purchasable && !purchased && (!hasVenues || (hasVenues && geolocated && venues.length === 0));

  return (
    <div className="Event">
      <div className="image" style={{ backgroundImage: `url(${thumbnail})`}} />
      <div className="metadata">
        <div className="mobile-image" style={{ backgroundImage: `url(${thumbnail})`}} />
        <div className="availability">{availability}</div>
        <div className="name">{name}</div>
        <div className="secondary-title">{secondaryTitle}</div>
        <div className="date">{getDateLabel(rawEventDate, rawPurchasableUntil, estimatedRuntime, status, purchased)}</div>
        <div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
        { displayPrice && <Price price={price && price.price} currency={price && price.currency} /> }
        <div className="actions">
          <CartButton event={event} className={"large"} />
          { (availableNow && purchased && isLoggedIn) && <Button className="red large" onClick={() => history.push(`/events/${id}`)}>Watch Now</Button>   }
          { (!purchased || (purchased && !availableNow)) && <Button className="white large" onClick={() => history.push(`/events/${id}`)}>Learn More</Button> }
        </div>
      </div>
    </div>
  )
}

export default withStoreAndRouter()(Event);
