import React from "react";
import { ExternalLink } from "../HtmlElements";
import moment from "moment-timezone";
import config from "../../config";
import "./Footer.scss";

moment.tz.setDefault(config.MOMENT_TIMEZONE);

const Footer: React.FunctionComponent<any> = () => {
  const year = moment().format("YYYY");
  return (
    <footer className="Footer">
      <div className="content">
        <ul className="links">
          <li><ExternalLink href="https://www.metopera.org/user-information/terms-and-conditions/">Terms & Conditions</ExternalLink></li>
          <li><ExternalLink href="https://www.metopera.org/user-information/privacy-policy/">Privacy Policy</ExternalLink></li>
          <li><ExternalLink href="https://www.metopera.org/user-information/privacy-policy/#ourads">Our Digital Ads</ExternalLink></li>
        </ul>
        <div className="copyright">©{year} The Metropolitan Opera</div>
        <ul className="social-links">
          <li className="facebook"><ExternalLink href="https://www.facebook.com/MetOpera"></ExternalLink></li>
          <li className="twitter"><ExternalLink href="https://twitter.com/metopera"></ExternalLink></li>
          <li className="instagram"><ExternalLink href="https://www.instagram.com/metopera/"></ExternalLink></li>
          <li className="youtube"><ExternalLink href="https://www.youtube.com/user/MetropolitanOpera"></ExternalLink></li>
        </ul>
      </div>
    </footer>
  )
};

export default Footer;
