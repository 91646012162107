import React from "react";
import Fetch from "../../components/Fetch";
import EventDescription from "../../components/EventDescription";
import config from "../../config";
//import Carousel from "../../components/Carousel";
import FullContentMessage from "../../components/FullContentMessage";
import Button from "../../components/Button";
import { apiFetch, withStoreAndRouter, userLocation, getLocalStorageValue } from "../../utils";
import "./EventPage.scss";
import Carousel from "../../components/Carousel";

class EventPage extends React.Component<any, any> {
  startedEventExpiration = config.STARTED_EVENT_EXPIRATION_ENABLED;

  constructor(props) {
    super(props);

    this.state = {
      eventStarted: false,
      onWatchSelected: null,
    }
  };

  shouldComponentUpdate(nextProps) {
    if (
      this.props.globals.user.cart.length !== nextProps.globals.user.cart.length
      || this.props.globals.tempCart !== nextProps.globals.tempCart
    ) {
      return false;
    }
    return true;
  }

  showStartPopup = (callback) => {
    this.setState({
      startPopupShowing: true,
      onWatchSelected: callback,
    });
  }

  onStartPopupClosed = () => {
    this.setState({
      startPopupShowing: false,
      onWatchSelected: null,
    });
  }

  onStartPopupStart = (eventId) => {
    return async () => {
      const res = await apiFetch(`/events/${eventId}/start`, {
        method: "POST",
        body: JSON.stringify({ eventId })
      });
      if(res.ok) {
        const json = await res.json();
        if(json.status === "success") {
          this.state.onWatchSelected();
          this.onStartPopupClosed();
        }
      }
    }
  }

  render() {
    const { startPopupShowing } = this.state;
    const { globals: { components, dynamicStrings, geolocated } } = this.props;
    const userCoords = geolocated ? { lat: getLocalStorageValue("latitude"), long: getLocalStorageValue("longitude") } : null;
    return (
      <div className="EventPage">
        <Fetch url={`${config.PPV_API}/${window.location.pathname}`} query={userCoords} includeCredentials>
          {(loading, errors, data) => {
            const geoRestricted = data && data.geoRestricted,
              showPopupOnEventStart = this.startedEventExpiration && data && data.event && !data.event.started,
              event = data && data.event && { ...data.event, giftingEnabled: data.event.giftingEnabled ? true : false },
              hasVenues = event && event.hasVenues;

            if (hasVenues && !geolocated) {
              userLocation.get(this.props, navigator);
            }
            
            return (
              (loading || !data) ? null :
              <React.Fragment>
                {geoRestricted &&
                  <React.Fragment>
                    <FullContentMessage className="geo" title={dynamicStrings[config.DYNAMIC_STRINGS.GEO_RESTRICTION_TITLE]} body={dynamicStrings[config.DYNAMIC_STRINGS.GEO_RESTRICTION_MESSAGE]} />
                  </React.Fragment>
                }
                {!geoRestricted &&
                  <React.Fragment>
                    {startPopupShowing &&
                      <div className="start-popup">
                        <div className="start-popup-content">
                          <span className="start-popup-title">Start Event</span>
                          <span className="start-popup-message">{`Events expire ${config.STARTED_EVENT_EXPIRE_TIME} hours after you start watching them. Do you want to watch this event now?`}</span>
                          <div className="start-popup-actions">
                            <Button className="medium" onClick={this.onStartPopupClosed}>SAVE FOR LATER</Button>
                            <Button className="red medium" onClick={this.onStartPopupStart(event.id)}>WATCH NOW</Button>
                          </div>
                        </div>
                      </div>
                    }
                    <div className="event-area-desc">
                      <EventDescription event={event} showPopupOnEventStart={showPopupOnEventStart} showStartPopup={this.showStartPopup}/>
                    </div>
                    {/*RELATED/YOU MIGHT ALSO LIKE SECTION - Removed as requested
                    <div className="carousel-area">
                      <Carousel className="more-events" {...data.related} />
                    </div> */}
                  </React.Fragment>
                }
                {components && components.exploreTheMet && <div className="carousel-container"><Carousel className="explore" {...components.exploreTheMet} /></div>}
              </React.Fragment>
            )
          }}
        </Fetch>
      </div>
    )
  }
};

export default withStoreAndRouter()(EventPage);
