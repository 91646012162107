import React from "react";
import { NavLink } from "react-router-dom";
import { withStoreAndRouter, getQueryParam, resolveUser, getLocalStorageValue, fetchHandler, GetComponents } from "../../utils";
import { Logo, NavIcon } from "../../assets";
import LoginStatus from "../LoginStatus";
import { StorageField } from "./../../store";
import config from "./../../config";
import "./Header.scss";

class Header extends React.Component<any, any> {
  constructor(props) {
    super(props);

    this.state = {
      componentsRequested: false,
      dynamicStringsRequested: false
    }
  }

  componentDidMount() {
    const { updateTempCart } = this.props;
    const tempCart = getLocalStorageValue(StorageField.TempCart);
    if (tempCart) {
      updateTempCart(JSON.parse(tempCart));
    }

    let dynamicStringsPath = "/dynamic_strings?keys=";
    Object.keys(config.DYNAMIC_STRINGS).forEach((el) => {
      dynamicStringsPath += `${config.DYNAMIC_STRINGS[el]},`;
    });
    fetchHandler(`${config.CLIENT_API}${dynamicStringsPath}`, {}, this.onDynamicStringsFetchSuccess, null);
    fetchHandler(`${config.CLIENT_API}${config.REACT_APP_WEBSITE_SCREEN_PATH}`, {}, this.onComponentsFetchSuccess, null);
  }

  onDynamicStringsFetchSuccess = (data) => {
    const { setDynamicStrings } = this.props;

    if (data && data.dynamicStrings && data.dynamicStrings.nodes) {
      const dynamicStrings = {};
      data.dynamicStrings.nodes.forEach((el) => {
        dynamicStrings[el.key] = el.value;
      });
      setDynamicStrings(dynamicStrings);
    }

    this.setState({
      dynamicStringsRequested: true,
    })
  }

  onComponentsFetchSuccess = async (data) => {
    const { globals: { user: { fetched } }, history, onLoad, setComponents } = this.props,
      email = getQueryParam("e"),
      components = data && data.screen && data.screen.components;

    if (components) {
      setComponents(GetComponents(components));
    }

    // if (getLocalStorageValue(StorageField.LoggedIn) !== "false" && !fetched) {
      this.fetchUser();
    // }
    // else {
    //   onLoad();
    //   const pathName =  window.location.pathname,
    //     page = pathName.split("/")[1];
    //   if (page && (page === "cart" || (page === "events" && email)))
    //     history.push("/login", { returnPath: pathName });
    // }

    this.setState({
      componentsRequested: true,
    })
  }

  fetchUser = () => {
    this.props.fetchUser();
    fetchHandler(`${config.CLIENT_API}/user_info`, {}, this.onUserFetchSuccess, null);
  }


  onUserFetchSuccess = async (user) => {
    const { onLoad, history } = this.props;
    await resolveUser(this.props, user, () => {
      onLoad();
      const pathName =  window.location.pathname,
        page = pathName.split("/")[1];
      const queryParams = window.location.href.split("?")
      const forceLogin = queryParams[1] && queryParams[1].includes("&force_login")
      if ((page && (page === "cart" || page === "events") && !user.data) || (!user.data && forceLogin))
        history.push("/login", { returnPath: pathName });
    }, () => {
      const pathName =  window.location.pathname,
      page = pathName.split("/")[1];
    const queryParams = window.location.href.split("?")
    const forceLogin = queryParams[1] && queryParams[1].includes("&force_login")
      if ((page && (page === "cart" || page === "events") && !user.data) || (!user.data && forceLogin))
        history.push("/login", { returnPath: pathName });
    });
    onLoad();
  }

  onNavButtonClick = function (e){
    e.stopPropagation();

    let mobileMenu = document.getElementById("mobile-menu");
    if (!mobileMenu) return;

    if (mobileMenu.style.display === "block") {
      mobileMenu.style.display = "none";
    } else {
      mobileMenu.style.display = "block";
    }
  }

  onCartButtonClick = (e) => {
    const { globals: { user: { fetched } }, history } = this.props
    if (!fetched) {
      e.preventDefault();
      history.push("/login", { returnPath: "/cart" });
    }
  }

  render() {
    const { globals: { user: { cart: userCart, fetched }, tempCart } } = this.props,
      cart = fetched ? userCart : tempCart,
      { componentsRequested, dynamicStringsRequested } = this.state,
      mounted = componentsRequested && dynamicStringsRequested;
    return (
      <header className="Header">
        <div className="content">
          <div className="nav-humburger-button" onClick={this.onNavButtonClick} ><NavIcon /></div>
          <ul id="mobile-menu">
            <li><NavLink activeClassName="selected" exact to="/">Events</NavLink></li>
            <li><NavLink activeClassName="selected" exact to="/how-it-works">How It Works</NavLink></li>
            <li>{mounted && <LoginStatus />}</li>
          </ul>
          <ul className="menu">
            <li><NavLink activeClassName="selected" exact to="/">Events</NavLink></li>
            <li><NavLink activeClassName="selected" exact to="/how-it-works">How It Works</NavLink></li>
          </ul>
          <a className="logo" href="https://www.metopera.org/"><Logo /></a>
          <div className="menu right">
            {mounted && <LoginStatus />}
            <div className="cart"><NavLink activeClassName="selected" exact to="/cart" onClick={this.onCartButtonClick}>{`Cart (${(cart && cart.length) || 0})`}</NavLink></div>
          </div>
        </div>
      </header>
    )
  }
};
export default withStoreAndRouter()(Header);
