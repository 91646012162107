import React from "react";
import { withStore } from "../../store";
import EmptyCart from "./EmptyCart";
import PopulatedCart from "./PopulatedCart";
import "./Cart.scss";

const Cart: React.FunctionComponent<any> = ({ globals: { user: { cart } } }) => {
  return cart.length === 0 ? <EmptyCart /> : <PopulatedCart />
};

export default withStore(Cart);
