import React from "react";
import { withStoreAndRouter } from "../../utils";
import CheckoutProgress from "./CheckoutProgress";
import CheckoutTotal from "./CheckoutTotal";
import classnames from "classnames";
import "./CheckoutContainer.scss";

const CheckoutContainer: React.FunctionComponent<any> = ({ className, header, step, onSubmit, initState, updateGiftState, children }) => {
  return (
    <div className={classnames("CheckoutContainer", className)}>
      <div className="info">
        <CheckoutProgress step={step} {...initState} />
        <div className="header">{header}</div>
        { children }
      </div>
      <div className="total">
        <CheckoutTotal step={step} {...initState} updateGiftState={updateGiftState} onSubmit={onSubmit} />
      </div>
    </div>
  )
}

export default withStoreAndRouter()(CheckoutContainer);
