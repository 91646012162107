import React from "react";
import { RemoveButton } from "../Button";
import { getDateLabel } from "../../utils";
import "./CartItem.scss";
import classnames from "classnames";
import moment from "moment-timezone";
import getSymbolFromCurrency from 'currency-symbol-map';

const CartItem: React.FunctionComponent<any> = ({ id, thumbnail, name, price, eventDate, purchasableUntil, purchasedError, giftPurchasedError, estimatedRuntime, status, currency, secondaryTitle }) => {
  const expirationError = moment().isSameOrAfter(moment(purchasableUntil));
  return (
    <div className="CartItemContainer">
      <div className="CartItem">
        <div className="image"><img alt="event" src={thumbnail} /></div>
        <div className="meta">
          <div className="info">
            <div className={classnames("error-msg-item", {error: purchasedError})}>You already have a ticket to this event. Please remove from cart before continuing.</div>
            <div className={classnames("error-msg-item", {error: giftPurchasedError})}>This recipient has a ticket to this event. Please remove from cart before continuing.</div>
            <div className={classnames("error-msg-item", {error: expirationError})}>This event has expired. Please remove from cart before continuing.</div>
            <div className="name">{name}</div>
            <div className="secondary-title">{secondaryTitle}</div>
            <div className="date">{getDateLabel(eventDate, purchasableUntil, estimatedRuntime, status)}</div>
          </div>
          <RemoveButton id={id} className="remove-btn" />
          <div className="price">{`${getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : ''}${price.toFixed(2)} ${currency}`}</div>
        </div>
      </div>
    </div>
  );
}

export default CartItem;
