import React from "react";
import Button from "../../components/Button";
import { withRouter } from "react-router-dom";

const EmptyCart: React.FunctionComponent<any> = ({ history }) => {
  return (
    <div className="Cart empty">
      <div className="header">Your cart is empty.</div>
      <div className="message">You haven’t added any events to your cart yet. See what’s coming soon and available by browsing events.</div>
      <Button className="white xlarge" onClick={() => history.push("/")}>View events</Button>
    </div>
  )
};

export default withRouter(EmptyCart);
