export default {
  STRIPE_PUBLISHABLE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
  EMAIL_API: process.env.REACT_APP_EMAIL_API,
  CLIENT_API: process.env.REACT_APP_CLIENT_API,
  PPV_API: process.env.REACT_APP_PPV_API,
  MOMENT_TIMEZONE: "America/New_York",
  BC_ACCOUNT_ID: process.env.REACT_APP_BC_ACCOUNT_ID,
  PLAYER_ID: process.env.REACT_APP_BC_PLAYER_ID,
  PRE_AVAILABILITY_TIME: process.env.REACT_APP_PRE_SHOW_TIME,
  MET_HOWITWORKS_URL: process.env.REACT_APP_MET_HOWITWORKS_URL,
  BREAKPOINT_TABLET: 776,
  STARTED_EVENT_EXPIRATION_ENABLED: false,
  STARTED_EVENT_EXPIRE_TIME: 72, // Hours
  REACT_APP_WEBSITE_SCREEN_PATH: process.env.REACT_APP_WEBSITE_SCREEN_PATH,
  DYNAMIC_STRINGS: {
    NO_EVENTS_TITLE: "met_at_home_no_events_title",
    NO_EVENTS_MESSAGE: "met_at_home_no_events_message",
    GEO_RESTRICTION_TITLE: "met_at_home_geo_restriction_title",
    GEO_RESTRICTION_MESSAGE: "met_at_home_geo_restriction_message",
    ENABLE_LOCATION_MESSAGE: "met_at_home_enable_location_message",
    GEO_LOCATION_MESSAGE: "met_at_home_geo_location_message",
    NO_STREAMING_AVAILABLE_TITLE: "met_at_home_no_streaming_available_title",
    NO_STREAMING_AVAILABLE_MESSAGE: "met_at_home_no_streaming_available_message",
  },
  AUTH_REQUIRED_ROUTES: [
    "/cart",
    "/checkout",
    "/confirmation"
  ],
};
