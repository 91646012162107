import React from "react";
import { getQueryUrl } from "../../utils";
import { withStore, StoreState } from "../../store";

interface FetchProps {
  url?: string;
  method: string;
  body?: any;
  query?: any;
  options?: any;
  children: (loading?: boolean, errors?: any[], data?: any, status?: number, refetch?: Function) => React.ReactNode;
  onSuccess?: (data?: any, status?: number) => void;
  onError?: (errors?: any[], status?: number) => void;
  onComplete?: (data?: any, errors?: any[], status?: number) => void;
  includeCredentials?: boolean;
  manual?: boolean;
  globals: StoreState;
};

class Fetch extends React.Component<FetchProps, any> {
  static defaultProps = {
    method: "GET"
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: undefined,
      data: undefined,
      status: undefined
    };
  }

  componentDidMount() {
    const { manual } = this.props;
    if(!manual)
      this.makeRequest();
  }

  componentDidUpdate(prevProps, prevState) {
    const { url, onError, onSuccess, onComplete, manual, query } = this.props;
    const { loading, errors, data, status } = this.state;

    if(((prevProps.url !== url) || (prevProps.query !== query)) && !manual) {
      this.makeRequest();
    }
    if(prevState.loading && !loading) {
      if(errors) {
        onError && onError(errors, status);
        onComplete && onComplete(undefined, errors, status);
      }
      else if(data) {
        onSuccess && onSuccess(data, status);
        onComplete && onComplete(data, undefined, status);
      }
    }
  }

  performFetch = (url, params) => {
    fetch(url, params)
      .then(async response => {
        const status = response.status;
        const contentType = response.headers.get('content-type');
        let loading = false;

        const data = contentType && contentType.includes('application/json') ?
          await response.json() :
          await response.text()

        if(response.ok) {
          this.setState({ loading, data, status });
        }
        else {
          this.setState({
            loading,
            errors: [{ message: data }],
            ...data, // contains `data` and possibly `errors` fields
            status
          })
        }
      })
      .catch(err => {
        this.setState({
          loading: false,
          errors: [{ message: err.message }]
        })
      })
  }

  makeRequest = () => {
    const { url, method, body, query, options, includeCredentials } = this.props;
    const resolvedUrl = (query && getQueryUrl(url, query)) || url;
    let params: any = { method, headers: {}, ...options };

    if(includeCredentials) {
      params.credentials = "include";
    }

    if(["POST", "PATCH", "PUT"].includes(method)) {
      params.headers["Content-Type"] = "application/json"
      if(body)
        params.body = JSON.stringify(body);
    }

    if(!resolvedUrl) {
      this.setState({ loading: false });
    }
    else {
      this.setState({ loading: true });
      this.performFetch(resolvedUrl, params);
    }
  }

  render() {
    const { loading, errors, data, status } = this.state;
    const { children } = this.props;
    return children(loading, errors, data, status, this.makeRequest);
  }
};

export default withStore(Fetch);
