import React from "react";
import Tile from "./Tile";
import EventTile from "./Tile/EventTile";
import classnames from "classnames";
import "./Carousel.scss";
import config from "../../config";
import Slider from "react-slick";

const Carousel: React.FunctionComponent<any> = ({ className, header, items }) => {

  const settings = {
    dots: true,
    autoplay: false,
    infinite: true,
    arrows: false,
    mobileFirst: true,
    responsive: [
    {
      breakpoint: 9999,
      settings: "unslick"
    },
    {
      breakpoint: config.BREAKPOINT_TABLET,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  }

  return (
    <div className={classnames("Carousel", className)}>
      { header && <div className="header">{header}</div> }
      { items &&
          <Slider {...settings} className="items">
            { items.length && items[0].tile ?
                items.map((item, index) => <Tile key={index} item={item.tile} metadata={item.metadata} />) :
                items.map((item, index) => {
                  if (Object.keys(item).length !== 0) {
                    return <EventTile key={index} item={item} />
                  }
                  return undefined;
                })
            }
          </Slider>
      }
    </div>
  )
}

export default Carousel;
