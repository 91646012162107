import React from "react";
import { withStoreAndRouter, loginHandler, resolveUser, fetchHandler } from "../../utils";
import { Alert } from "../../assets/index";
import { ExternalLink } from "../../components/HtmlElements"
import Button from "../../components/Button";
import "./Login.scss";
import _ from "lodash";
import config from "../../config";

class Login extends React.Component<any, any> {

  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      errors: {}
    };
  }

  componentDidMount() {
    fetchHandler(`${config.CLIENT_API}/user_info`, {}, (response) => {
      if (response.data) {
        this.onSuccess(response);
      }
    }, null);
  }

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  focusOnInput = (inputName) => {
    const input = document.querySelector(`input[name="${inputName}"]`);
    if (input) {
      (input as HTMLElement)?.focus();
      window.scrollTo({top: (input as HTMLElement)?.offsetTop - 50, behavior: "smooth"});
    }
  }

  onLoginSuccess = (response) => {
    if (response) {
      fetchHandler(`${config.CLIENT_API}/user_info`, {}, this.onSuccess, null);
    }
  }
  /*

  const { onLoad, history } = this.props;
    await resolveUser(this.props, user, () => {
       onLoad();
      const pathName =  window.location.pathname,
        page = pathName.split("/")[1];
      if (page && (page === "cart" || (page === "events" && user.email)))
        history.push("/login", { returnPath: pathName });
    });

  */

  onSuccess = (response) => {
    resolveUser(this.props, response, this.onGetUserSuccess);
  }

  onGetUserSuccess = () => {
    const { onLoad } = this.props
     onLoad()
    const { location, history } = this.props,
      returnPath = location && location.state && location.state.returnPath;
    history.push(returnPath ? returnPath : "/");
  }

  onError = (error) => {
    console.log(error);
    const errors = {
      auth: true,
    };
    this.setState({
      errors: errors,
    });
  }

  onSubmit = (e) => {
    const { username, password } = this.state,
      errors = {};
    e.preventDefault();

    errors["username"] = _.isEmpty(username);
    errors["password"] = _.isEmpty(password);

    if(!errors["username"] && !errors["password"]) {
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ username, password })
      };
      loginHandler(`${config.CLIENT_API}/login`, options, this.onLoginSuccess, this.onError);
    }
    else {
      this.setState({
        errors: errors,
      });
    }
  }

  render() {
    const { username, password, errors } = this.state;
    return (
      <div className="Login">
        <div className="Login-body">
          <div className="Login-form">
            <form onSubmit={this.onSubmit}>
              <h1 className="Login-header">Login</h1>
              <h3>Returning Customers</h3>
              {(errors.auth || errors.username || errors.password) &&
                <div className="error-message-container">
                  {errors.auth && <p className="error-message">Sorry! We were unable to log you in with the Username and Password provided. If this is your first time here, please register now. Otherwise, please try again.</p>}
                  {(errors.username || errors.password) && <p className="error-message">Please fix the following error(s) on the form. Selecting one of the following link(s) will move the page to the form error.</p>}
                  <ol>
                    {errors.username && <li className="error-step">Please correct errors with your <span onClick={() => this.focusOnInput("username")}>email or username</span></li>}
                    {errors.password && <li className="error-step">Please correct errors with your <span onClick={() => this.focusOnInput("password")}>password</span></li>}
                  </ol>
                </div>
              }
              <label className={`form-field ${errors.username ? "form-field-error" : ""}`}>
                  <div className="form-field-input-label">
                    <span>Email or Username</span>
                    <span>Required</span>
                  </div>
                  <input
                    name="username"
                    type="text"
                    placeholder="janedoe@example.com or janedoe"
                    value={username}
                    onChange={this.onChange}
                  />
                  {errors.username && <span className="form-field-error-message"><Alert className="form-field-error-alert" />Email or Username is a required field</span>}
              </label>
              <label className={`form-field ${errors.password ? "form-field-error" : ""}`}>
                  <div className="form-field-input-label">
                    <span>Password</span>
                    <span>Required</span>
                  </div>
                  <input
                    name="password"
                    type="password"
                    placeholder="Type your password"
                    value={password}
                    onChange={this.onChange}
                  />
                  {errors.password && <span className="form-field-error-message"><Alert className="form-field-error-alert" />Password is a required field</span>}
              </label>
              <div className="form-field">
                <Button type='submit' theme="classic" className="red">Sign In</Button>
              </div>
              <div className="form-field forgot-password">
                <a href="https://www.metopera.org/account/forgot-password/">Forgot Username or Password?</a>
              </div>
              <div className="Login-disclaimer">
                <p>By signing in you agree to our <ExternalLink href="https://www.metopera.org/user-information/terms-and-conditions/">Terms of Service</ExternalLink> and acknowledge our <ExternalLink href="https://www.metopera.org/user-information/privacy-policy/">Privacy Policy</ExternalLink>.</p>
              </div>
            </form>
          </div>
          <div className="Login-register">
            <h3>New Customers</h3>
            <p>Create an account to receive special ticket offers, information about events, or sign up to receive email communications.</p>
            <Button onClick={() => window.open("https://www.metopera.org/account/register/", "_blank")} className="red">Register</Button>
          </div>
        </div>
      </div>
    )
  }

};

export default withStoreAndRouter()(Login);
