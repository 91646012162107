import React from "react";
import { apiFetch, toggleProcessing, withStoreAndRouter } from "../../utils";

class RemoveButton extends React.Component<any, any> {
  remove = async () => {
    const { update, id } = this.props;
    toggleProcessing(true);
    const res = await apiFetch("/cart/remove", {
      method: "POST",
      body: JSON.stringify({ id })
    });
    if(res.ok) {
      const json = await res.json();
      update(json);
    }
    toggleProcessing(false);
  }

  render() {
    const { className, } = this.props;
    return <div className={className} onClick={this.remove}>Remove</div>
  }
};
export default withStoreAndRouter()(RemoveButton);
