import React from "react";
import { withStoreAndRouter } from "../../utils";
import "./HowItWorks.scss";

const accountId = '102076671001';
const playerId = 'ShNTDWXQ';
const videoId = '6191038763001';
const iframeSrc = `https://players.brightcove.net/${accountId}/${playerId}_default/index.html?videoId=${videoId}`;

const HowItWorks: React.FunctionComponent<any> = ({ ...props }) => {
  const { components: { howItWorks } } = props.globals,
    innerHTML = {
      __html: (howItWorks && howItWorks.data && howItWorks.data.html) || ""
    };

  const divContainerStyle = {
    position: 'relative' as 'relative',
    display: 'block',
    maxWidth: '100%'
  };
  const divIframeStyle = {
    paddingTop: '56.25%'
  };
  const iframeStyle = {
    position: 'absolute' as 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    width: '100%',
    height: '100%'
  };
  return (<div dangerouslySetInnerHTML={innerHTML}></div>);
};

export default withStoreAndRouter()(HowItWorks);
