import React from "react";
import Button from "../Button";
import "./PopupModal.scss";

export default class PopupModal extends React.Component<any, any> {
  render() {
    const { title, message, onClose } = this.props;
    
    return (
      <div className="Popup-modal">
        <div className="Popup-modal-content">
          <span className="Popup-modal-title">{title}</span>
          <span className="Popup-modal-message">{message}</span>
          <div className="Popup-modal-actions">
            <Button className="medium" onClick={onClose}>GOT IT</Button>
          </div>
        </div>
      </div>
    );
  }
};
