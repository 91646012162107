import querystring from "querystring";
import { withStore, storageKey} from "./store";
import { withRouter } from "react-router";
import { compose } from "redux";
import moment from "moment-timezone";
import config from "./config";
import jwt_decode from "jwt-decode";

moment.tz.setDefault(config.MOMENT_TIMEZONE);

export const AvailableDateFormat = "MMMM D";
export const UpcomingDateFormat = "dddd, MMMM D, [at] h:mm A [ET]";
export const UpcomingTimeFormat = "d:hh:mm:ss";
export const InputDateFormat = "YYYY-MM-DTHH:mm:ss.SSSZ";

export function getDateLabel(rawEventDate, rawPurchasableUntil, estimatedRuntime, status, purchased = false) {
  const purchasableUntil = moment(rawPurchasableUntil, InputDateFormat);

  //Not available
  if (moment().isAfter(purchasableUntil)) return "This event is No Longer Available";

  //Without eventDate
  if (!rawEventDate) return "DATE TO BE ANNOUNCED";

  const isLive = status === 'LIVE' || status === 'PRE';
  const eventDate = moment(rawEventDate, InputDateFormat);

  //Live now
  if (isLive && estimatedRuntime && moment().isBetween(eventDate, moment(eventDate).add(estimatedRuntime, 'seconds'))) return "Live Now";

  //Available now until
  if (moment().isAfter(eventDate) && (status !== 'ENCORE' || purchased)) return `${status === 'ENCORE' ? "Watch Now Until" : "Available Until"} ${purchasableUntil.format(AvailableDateFormat)}`;

  return status === 'ENCORE' ? `Encore available from ${eventDate.format(AvailableDateFormat)} through ${purchasableUntil.format(AvailableDateFormat)}` : `Live: ${eventDate.format(UpcomingDateFormat).replace(":00 ", "")}`;
}

export function getQueryUrl(url, query) {
  return url ?
    query ?
      `${url}?${querystring.stringify(query)}` :
      url:
    undefined;
};

export function withStoreAndRouter() {
  return compose<any>(withStore, withRouter);
};

export function getLocalStorageValue(key) {
  return localStorage.getItem(storageKey(key));
}

export function setLocalStorageValue(key, value) {
  return localStorage.setItem(storageKey(key), value);
}

export function apiFetch(path, fetchOptions) {
  const url = `${config.PPV_API}${path}`;
  fetchOptions.credentials = "include";
  if(["POST", "PUT", "PATCH"].includes(fetchOptions.method))
    fetchOptions.headers = { "Content-Type": "application/json" };

  return fetch(url, fetchOptions);
};

export function toggleProcessing(processing, preventUnload = true) {
  const _App = document.querySelector(".App");
  if(processing) {
    _App && _App.classList.add("processing");
    if(preventUnload)
      window.onbeforeunload = function() { return true };
  } else {
    _App && _App.classList.remove("processing");
    window.onbeforeunload = null;
  }
};

export function parseCartResponse(cart) {
  if (cart) {
    return cart.map((el) => {
      return {
        ...el,
        id: el.videoId ? el.videoId : el.id,
      }
    });
  }
  return [];
}

export async function resolveUser(props, response, callback, noUserCallback?) {
  return new Promise(async (resolve) => {
    const { globals: { tempCart }, setUser, update, updateTempCart } = props;
    let json;
    const parsedUser = parseUserData(response);
    if (parsedUser.userId) {
      setUser(parsedUser);
      const cart = response && response.cart && parseCartResponse(response.cart),
        events = cart && tempCart.filter((item) => cart.findIndex((cartItem) => cartItem.id === item.videoId) === -1);
      if (events && events.length > 0) {
        const res = await apiFetch("/cart/add", {
          method: "POST",
          body: JSON.stringify({ events })
        });
        if(res.ok) {
          json = await res.json();
          update(json);
        }
      }
      updateTempCart([]);
      callback && callback();
    } else {
      if (noUserCallback) {
        noUserCallback()
      }
    }
  setTimeout(() => {
    resolve(json);
  }, 0);
  });
};

export function getQueryParam(param) {
  const params = new URLSearchParams(window.location.search);
  if (params.has(param)) {
      return params.get(param);
  } else {
      return undefined;
  }
};

export const parseUserData = (user) => {
  let result;
  if (user) {
    result = {}
    if (user.data && user.data.LoginInfo) {
      result["userId"] = user.data.LoginInfo.UserId;
      result["name"] = user.data.LoginInfo.UserId;
      result["email"] = user.data.LoginInfo.ElectronicAddress;
    }
    result = {
      ...result,
      cart: user.cart,
      events: user.events,
    }
  }
  return result;
}

export const fetchHandler = (url, options, onSuccess, onError) => {
  fetch(url, {...options, credentials: "include",  "user-platform": "Web", "Cache-Control": "no-store"})
    .then(response => response.text())
    .then(text => {
      try {
        const data = JSON.parse(text);
        onSuccess && onSuccess(data);
      } catch(err) {
        onSuccess && onSuccess(text);
      }
    }).catch((error) => {
      onError && onError(error);
    });
}

export const loginHandler = (url, options, onSuccess, onError) => {
  fetch(url, {...options, credentials: "include",  "user-platform": "Web", "Cache-Control": "no-store"})
    .then(response => response.text())
    .then(text => {
      try {
        const response = jwt_decode(text);
        onSuccess && onSuccess(response);
      } catch(err) {
        onError && onError(err);
      }
    }).catch((error) => {
      onError && onError(error);
    });
}

export const GetComponentByType = (components, type) => {
  if (components && components.length) {
    let component;
    component = components.find((el) => {
      return el.type.toLowerCase() === type.toLowerCase() || el.name.toLowerCase() === type.toLowerCase();
    });
    if (component) {
      const componentNodes = component.collection &&
        component.collection.items &&
        component.collection.items.nodes;
      if (componentNodes && componentNodes.length) {
        return {
          header: component.header,
          items: [
            ...componentNodes
          ],
        }
      }
      else if (component.type.toLowerCase() === "editorial") {
        return {
          ...component,
        }
      }
    }
  }
  return undefined;
}

export const GetComponents = (components) => {
  return {
    hero: { ...GetComponentByType(components, "hero") },
    exploreTheMet: { ...GetComponentByType(components, "explore the met") },
    sponsors: { ...GetComponentByType(components, "sponsors") },
    howItWorks: { ...GetComponentByType(components, "how it works") }
  }
}

export const userLocation = (function() {
  // let userLocationDeniedCount = 0;
  let isPending = false;

  // const defaultCoords = {
  //   lat: "40.771271",
  //   long: "-73.983437"
  // };

  return {
    get: (props, navigator) => {
      if (navigator.geolocation) {
        if (!isPending) {
          isPending = true;

          navigator.geolocation.getCurrentPosition(
            (position) => {
              isPending = false;

              const { latitude, longitude } = position.coords;
              localStorage.setItem(storageKey("latitude"), latitude.toString());
              localStorage.setItem(storageKey("longitude"), longitude.toString());

              props.setGeolocationStatus();
            },
            (error) => {
              isPending = false;
              // userLocationDeniedCount++;

              // if (userLocationDeniedCount > 2) {
              //   localStorage.setItem(storageKey("latitude"), defaultCoords.lat);
              //   localStorage.setItem(storageKey("longitude"), defaultCoords.long);
              //   props.setGeolocationStatus();
              // }
            }
          );
        }
      }
      // else {
      //   /* geolocation functionality is not available on browser */
      //   localStorage.setItem(storageKey("latitude"), defaultCoords.lat);
      //   localStorage.setItem(storageKey("longitude"), defaultCoords.long);
      //   props.setGeolocationStatus();
      // }
    }
  };
})();
