import React from "react";
import classnames from "classnames";
import { withStore } from "../../../store";
import Button from "../../Button";
import { ExternalLink } from "../../HtmlElements";
import { Link } from "react-router-dom";
import "./CheckoutTotal.scss";
import { apiFetch, toggleProcessing } from "../../../utils";
import moment from "moment-timezone";
import getSymbolFromCurrency from 'currency-symbol-map';

class CheckoutTotal extends React.Component<any, any> {
  promoInput;

  constructor(props) {
    super(props);

    const { globals: { user: { cart } } } = this.props;
    const isGift = cart.reduce((acc, item) => acc || item.isGift, false);
    const { subTotal, total, count } = this.computePayment(cart, props.discount, isGift);
    const step = props.step || 1;
    const submitText = step === 1 ? "Proceed to Payment" : "Complete Purchase";

    this.state = {
      step: props.step || 1,
      discount: props.discount || null,
      invalidPromo: false,
      isGift,
      subTotal,
      total,
      submitText,
      count,
      cartErrors: false
    }
  };

  computePayment = (cart, discount, isGift) => {
    const { subTotal, count } = cart.reduce((acc, item) => {
      return { subTotal: acc.subTotal + item.price, count: acc.count + 1};
    }, { subTotal: 0, count: 0 });
    let total = subTotal;
    if(discount) {
      if(discount.percentOff)
        total = total * (100 - discount.percentOff)/100;
      else if(discount.amountOff)
        total -= discount.amountOff;
    }
    const currency = cart && cart.length ? cart[0].currency : undefined;

    return {subTotal, total, count, currency};
  }

  onRemoveDiscount = () => {
    this.setState({ discount: null });
  }

  onAddDiscount = async () => {
    const res = await apiFetch("/cart/verify_promo", {
      method: "POST",
      body: JSON.stringify({ code: this.promoInput.value })
    });
    if(res.ok) {
      const json = await res.json();
      if(json.status === "success") {
        this.setState({ discount: json.coupon });
      } else {
        this.setState({ invalidPromo: true });
        this.promoInput.value = "";
      }
    }
  }

  onSubmit = async () => {
    const { update, onSubmit } = this.props;
    const res = await apiFetch("/cart/fetch", {
      method: "GET",
    });
    if(res.ok) {
      const json = await res.json();
      update(json);

      const expiredItemsError = json.cart.reduce((acc, item) => {
        return acc || moment().isSameOrAfter(moment(item.purchasableUntil));
      }, false);

      this.setState({ cartErrors: expiredItemsError });
    }
    onSubmit && onSubmit(this.state);
  }

  onToggleGift = async (e: React.ChangeEvent<HTMLInputElement>)=> {
    const isGift = e.target.checked;

    const { globals: { user: { cart } }, update } = this.props;

    const cartEvents = cart.map(item => {
      return {
        videoId: item.id,
        stripeProductId: item.productId,
        isGift,
        giftingEnabled: item.giftingEnabled,
      }
    });

    toggleProcessing(true);
    const res = await apiFetch("/cart/add", {
      method: "POST",
      body: JSON.stringify({ events: cartEvents })
    });
    if(res.ok) {
      const json = await res.json();
      if (this.props.updateGiftState) this.props.updateGiftState(isGift);
      json.cart.forEach(item => {
        item.isGift = isGift;
      });
      update(json);
      this.setState({ isGift });
    }
    toggleProcessing(false);
  }

  render() {
    const { globals: { user: { cart } }, discount } = this.props;
    const giftingAvailable = cart.reduce((acc, item) => acc && item.giftingEnabled, true);
    const isGift = cart.reduce((acc, item) => acc || item.isGift, false);
    const { subTotal, total, count, currency } = this.computePayment(cart, discount, isGift);
    const expiredItemsError = cart.reduce((acc, item) => {
      return acc || moment().isSameOrAfter(moment(item.purchasableUntil));
    }, false);
    const currencySymbol = getSymbolFromCurrency(currency) ? getSymbolFromCurrency(currency) : "";

    return (
      <div className={classnames("CheckoutTotal", { "step1": this.state.step === 1, "step2": this.state.step === 2 })}>
        <div className="header">Total</div>
        <div className="line-item subtotal">
          <div className="label">Subtotal</div>
          <div className="value">{`${currencySymbol}${subTotal.toFixed(2)} ${currency}`}</div>
        </div>
        { discount &&
          <div className="line-item discount">
            <div className="label">{discount.id}</div>
            <div className="value">
              { discount.amountOff && `-$${discount.amountOff.toFixed(2)}`}
              { discount.percentOff && `-${discount.percentOff}%`}
            </div>
          </div>
        }
        <div className={classnames("add-promo", { error: this.state.invalidPromo })}>
          <div className="error-msg"><div>!</div> Invalid Promo Code</div>
          <div>
            <input
              className="promo-code"
              type="text"
              placeholder="Add a promo code"
              ref={node => this.promoInput = node}
              onFocus={() => this.setState({ invalidPromo: false })}
            />
            <Button black noReact onClick={this.onAddDiscount}>Apply</Button>
          </div>
        </div>
        <div className="line-item total">
          <div className="label">Total</div>
          <div className="value">{`${currencySymbol}${total.toFixed(2)} ${currency}`}</div>
        </div>
        <div className="disclaimer">
          By completing your purchase you are agreeing to our <ExternalLink href="https://www.metopera.org/user-information/terms-and-conditions/">TERMS OF SERVICE</ExternalLink>.
        </div>
        {giftingAvailable &&
          <div className="git-checkbox">
            <div><input type="checkbox" value={isGift} disabled={this.state.step !== 1} defaultChecked={isGift} onChange={this.onToggleGift}></input></div>
            <div>This order is a gift</div>
          </div>
        }
        <div className="main-btn">
          <Button black noReact disabled={count === 0 || expiredItemsError} onClick={this.onSubmit}>{this.state.submitText}</Button>
        </div>
        <div className="continue-btn">
          <Link to="/">Continue Shopping</Link>
        </div>
      </div>
    )
  }
};

export default withStore(CheckoutTotal);
