import React from "react";
import Button from "../Button";
import { withStoreAndRouter } from "../../utils";
import "./Hero.scss";
import config from "../../config";

const Hero: React.FunctionComponent<any> = ({ history, events, html, ...props }) => {
  const { components: { hero }, dynamicStrings } = props.globals,
    data = hero && hero.items[0],
    slide = data && data.slide;
  return (
    <div className="Hero">
    { slide &&
      <React.Fragment>
        <div className="image"><img alt="hero" src={slide.image.url} /></div>
        <div className="header">{slide.header}</div>
        <p className="geolocation-message" dangerouslySetInnerHTML={{__html: dynamicStrings[config.DYNAMIC_STRINGS.GEO_LOCATION_MESSAGE]}}></p>
        <div className="description" dangerouslySetInnerHTML={{ __html: slide.description }}></div>
        <Button className="how_button large" white onClick={() => history.push("/how-it-works")}>How It Works</Button>
        {/* <AddAllButton events={events} /> */}
      </React.Fragment>
    }
    </div>
  );
};

export default withStoreAndRouter()(Hero);
