import React from "react";
import ReactPlayerLoader from '@brightcove/react-player-loader';
import config from "../../config";
import "./Player.scss";

class Player extends React.Component<any, any> {

  onSuccess = (success) => {
    const player = success.ref;
    player.one('bc-catalog-error', () => {
      if (player.catalog.error !== undefined) {
        const specificError = player.catalog.error.data[0];
        this.props.onCatalogError(true);
        console.log('bc-catalog-error:', specificError);
      }
    });

    player.on('error', (e) => {
      switch (player.error().code) {
        case 4:
        default: this.props.onSourceError(true);
      }

    });

    // this.props.onSuccess()
  }

  render() {
    const { videoId, className } = this.props;
    return (
      <ReactPlayerLoader accountId={config.BC_ACCOUNT_ID} videoId={videoId} playerId={config.PLAYER_ID} attrs={{ className }} onSuccess={this.onSuccess}/>
    )
  }

};

export default Player;
