import React from "react";
import { VisaCard, DiscoverCard, AmexCard, MasterCard } from "../../assets";
import { withStoreAndRouter, apiFetch, toggleProcessing } from "../../utils";
import { Redirect } from "react-router-dom";
import CheckoutContainer from "../../components/CheckoutContainer";
import { Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import csc from "country-state-city";
import config from "../../config";
import _ from "lodash";
import "./Checkout.scss";
import moment from "moment-timezone";

const standardOptions = {
  classes: { invalid: "invalid" },
  style: {
    base: { lineHeight: "25px" },
    invalid: { color: "#c61c1c" },
    empty: { color:"#c61c1c" }
  }
};

const fields = [{
  label: "Name",
  value: "giftName",
  required: true,
  giftSection: true
},
{
  label: "Email Address",
  value: "giftEmail",
  required: true,
  giftSection: true
},
{
  label: "Confirm Email Address",
  value: "giftConfirmEmail",
  required: true,
  giftSection: true
},
{
  label: "Name",
  value: "billingName",
  required: true
},
{
  label: "Email Address",
  value: "email",
  required: false
},
{
  label: "Billing Address 1",
  value: "line1",
  required: true
},
{
  label: "Billing Address 2",
  value: "line2"
},
{
  label: "City",
  value: "city",
  required: true
},
{
  label: "State/Province",
  value: "billing_state",
  required: true,
  getComponent: true
},
{
  label: "Country",
  value: "country",
  required: true,
  getComponent: true,
  validate: false
},
{
  label: "Postal Code",
  value: "postal_code"
},
{
  label: "Phone Number",
  value: "phone"
},
{
  value: "name",
  required: true,
  display: false
}];

class Checkout extends React.Component<any, any> {
  stripe;
  elements;
  name;
  billing_state;
  serverError;

  cardNumber = false;
  cardExpiry = false;
  cardCvc = false;

  constructor(props) {
    super(props);

    const { globals: { user: { cart } } } = this.props;
    const isGift = cart.reduce((acc, item) => acc || item.isGift, false);

    const defaultCountry = csc.getCountryByCode("US");
    this.state = {
      paymentIntent: _.get(props, "location.state.checkout.paymentIntent"),
      loadedStripe: false,
      country: defaultCountry.sortname,
      countryId: defaultCountry.id,
      isGift
    }
  }

  async componentDidMount() {
    this.stripe = await loadStripe(config.STRIPE_PUBLISHABLE_KEY!);
    this.setState({ loadedStripe: true });
  }

  shouldComponentUpdate(nextProps, nextState) {
    if(nextState.loadedStripe !== this.state.loadedStripe ||
      nextState.country !== this.state.country ||
      nextState.countryId !== this.state.countryId) {
      return true;
    }
    return false;
  }

  getComponent = (type) => {
    const { countryId } = this.state;

    if(type === "billing_state") {
      const isUS = csc.getCountryById(countryId).sortname === "US";
      let stateList = [
        { id: "none", name: "---" },
        ...csc.getStatesOfCountry(countryId)
      ];

      if(isUS) {
        stateList = _.sortBy([
          ...stateList,
          { id: "DC", name: "District of Columbia" }
        ], ["name"])
      }

      return (
        <div className="select_wrapper">
          <select ref={node => this.billing_state = node} onChange={(event) => this.onChange("billing_state", event.target.value)}>
          { stateList.map(state => (
            <option key={state.id} value={state.name}>{state.name}</option>
          ))}
          </select>
        </div>
      )
    } else if(type === "country") {
      const countryList = csc.getAllCountries();
      return (
        <div className="select_wrapper">
          <select onChange={this.onCountryChange} defaultValue={csc.getCountryByCode("US").sortname}>
          { countryList.map(country => (
            <option key={country.id} value={country.sortname}>{country.name}</option>
          ))}
          </select>
        </div>
      )
    }
    return null
  }

  onChange = (fieldName, value) => {
    const ref = this[fieldName];
    try {
      ref.closest(".payment_field, .stripe_field").classList.remove("error");
    } catch(err) {}

    ref && ref.classList && ref.classList.remove("error");

    if(fieldName === "name") {
      let _err = document.querySelector(".card_errors .name");
      if(value.length > 0) {
        (_err as HTMLElement).style.display = "none";
      }
      else {
        ref.closest(".payment_field, .stripe_field").classList.add("error");
        (_err as HTMLElement).style.display = "block";
      }
    }
    else if(fieldName === "phone") {
      value = value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
      ref.value = value;
    }

    this.setState({ [fieldName]: value });
  }

  onCardNameBlur = () => {
    let _err = document.querySelector(".card_errors .name");
    if(_err) (_err as HTMLElement).style.display = this.name.value.length > 0 ? "none" : "block";
  }

  onCountryChange = (event) => {
    const country = csc.getCountryByCode(event.target.value);
    this.setState({
      country: country.sortname,
      countryId: country.id
    });
  }

  onCardChange = (event) => {
    const message = _.get(event, "error.message");
    const field = event.elementType.toLowerCase();
    const _error = document.querySelector(`.card_errors .${field} span`);
    if(message && _error) _error.innerHTML = message;
    this[event.elementType] = event.complete;
    let _err = document.querySelector(`.card_errors .${field}`);
    (_err as HTMLElement).style.display = event.complete ? "none" : "block";
  }

  toggleSubmitButton = (processing) => {
    const _button = document.querySelector(".CheckoutTotal .main-btn button");
    if(_button) {
      _button.innerHTML = processing ? "Processing..." : "Complete Purchase";
    }
  }

  toggleServerError = (message) => {
    const _serverError = this.serverError as HTMLElement;
    if(message) {
      _serverError.innerText = message;
      _serverError.classList.add("visible");
      window.scrollTo(0, 0);
    } else {
      _serverError.classList.remove("visible");
    }
  }

  onSubmit = async () => {
    const { history } = this.props;
    const { paymentIntent, name: nameOnCard, giftName, giftEmail, billingName: name, phone, line2, postal_code } = this.state;

    let valid = true;
    this.toggleServerError(false);

    // Validate billing fields and show error if applicable
    for(let field of fields) {
      if (!this.state.isGift && field.giftSection) continue;

      if(field.required && (!this.state[field.value] || this.state[field.value] === "---") && field.validate !== false) {
        try {
          this[field.value].closest(".payment_field, .stripe_field").classList.add("error")
        } catch(err) {}
        valid = false;
      }
    }

    if (this.state.isGift) {
      if (this.state['giftEmail'] !== this.state['giftConfirmEmail']) {
        try {
          this['giftConfirmEmail'].closest(".payment_field, .stripe_field").classList.add("error")
          this['giftConfirmEmail'].parentNode.querySelector('.error-message > span').innerHTML = 'Confirm Email Address should match Email Address';
        } catch(err) {}
        valid = false;
      } else {
        this['giftConfirmEmail'].parentNode.querySelector('.error-message > span').innerHTML = 'Confirm Email Address is a required field';
      }
    }

    ["name", "cardNumber", "cardExpiry", "cardCvc"].forEach(field => {
      const invalid = field === "name" ? !this.name.value : this[field] === false;
      if(invalid) {
        let _err = document.querySelector(`.card_errors .${field.toLowerCase()}`);
        if(_err) (_err as HTMLElement).style.display = "block";
        valid = false;
      }
    });

    if(!valid) {
      window.scrollTo(0, 0);
    } else {
      const { globals: { user: { cart, userId, email: userEmail } }, update } = this.props;
      const expiredItemsError = cart.reduce((acc, item) => {
        return acc || moment().isSameOrAfter(moment(item.purchasableUntil));
      }, false);

      if (expiredItemsError) {
        history.replace('/cart');
        return;
      }

      let error = false;

      let encodedEmail = '';

      if (this.state.isGift) {
        encodedEmail = Buffer.from(this.state['giftEmail']).toString('base64')
      } else {
        encodedEmail = Buffer.from(userEmail).toString('base64')
      }

      const res = await apiFetch(`/cart/gift?u=${encodedEmail}`, { method: "GET" });
      if(res.ok) {
        const json = await res.json();
        if (Array.isArray(json.events) && (json.events.length > 0)) {
          cart.forEach(item => {
            if (json.events.find(e => e.id === item.id || e.videoId === item.id)) {
              if (this.state.isGift) {
                item.giftPurchasedError = true;
                error = true;
              } else {
                item.purchasedError = true;
                error = true;
              }
            }
          });
          if (error) {
            if (this.state.isGift) {
              update({cart, userId, giftEvents: json.events});
            } else {
              update({cart, userId, events: json.events});
            }
            history.replace('/cart');
            return;
          }
        }
      }

      const address = {
        ..._.pick(this.state, [
          "line1",
          "city",
          "country"
        ]),
        state: this.state.billing_state,
        line2: line2 && line2.length > 0 ? line2 : undefined,
        postal_code: postal_code && postal_code.length > 0 ? postal_code : undefined
      };

      const card = this.elements.getElement(CardNumberElement, { name: nameOnCard });
      toggleProcessing(true);
      this.toggleSubmitButton(true);
      const result = await this.stripe.confirmCardPayment(paymentIntent, {
        payment_method: {
          card,
          billing_details: {
            name,
            email: userEmail,
            phone: phone && phone.length > 0 ? phone : undefined,
            address
          }
        }
      });
      if(result.error) {
        toggleProcessing(false);
        this.toggleSubmitButton(false);
        this.toggleServerError(result.error.message);
      } else if(result.paymentIntent.status === "succeeded") {
        if (this.state.isGift) {
          result.mail = userEmail;
          result.giftEmail = giftEmail;
          result.giftName = giftName;
        }
        const res = await apiFetch("/cart/purchase", {
          method: "POST",
          body: JSON.stringify(result)
        });
        if(res.ok) {
          const json = await res.json();
          if(json.status === "success") {
            toggleProcessing(false);
            history.replace(`/confirmation/${json.order}`);
          }
          else {
            toggleProcessing(false);
            this.toggleSubmitButton(false);
            this.toggleServerError(json.error);
          }
        }
        await apiFetch("/cart/log", {
          method: "POST",
          body: JSON.stringify(result)
        });
        toggleProcessing(false);
      }
    }
  }

  updateGiftState(isGift) {
    this.setState({ isGift });
    this.forceUpdate()
  }

  render() {
    const { globals: { user: { email: userEmail } }, location } = this.props;
    const { loadedStripe } = this.state;

    const cardNumberOptions = _.merge({}, standardOptions, {
      classes: { base: "stripe_input card_number" },
      placeholder: ""
    });

    const cardExpiryOptions = _.merge({}, standardOptions, {
      classes: { base: "stripe_input card_expiry" }
    });

    const cardCvvOptions = _.merge({}, standardOptions, {
      classes: { base: "stripe_input card_cvv" },
        placeholder: ""
    });

    return (
      location.state ?
        loadedStripe ?
        <CheckoutContainer className="Checkout" header="Your Information" updateGiftState={this.updateGiftState.bind(this)} initState={location.state.checkout} onSubmit={this.onSubmit}>
          <div ref={node => this.serverError = node} className="server_error"></div>
          <section className="cards">
            <div className="payment_header">Payment</div>
            <div>
              <img alt="visa" src={VisaCard} />
              <img alt="discover" src={DiscoverCard} />
              <img alt="amex" src={AmexCard} />
              <img alt="master card" src={MasterCard} />
            </div>
          </section>

          <section className="card">
            <div className="payment_header">Card Info</div>
            <div className="card_info">
              <Elements stripe={this.stripe}>
                <ElementsConsumer>
                  {({ elements }) => {
                    this.elements = elements;
                    return (
                      <React.Fragment>
                        <div className="stripe_field">
                          <label>Card Number</label>
                          <label>Required</label>
                          <CardNumberElement options={cardNumberOptions} onChange={this.onCardChange} />
                        </div>

                        <div className="row">
                          <div className="stripe_field name">
                            <label>Name on Card</label>
                            <label>Required</label>
                            <input
                              type="text"
                              ref={node => this.name = node}
                              onBlur={this.onCardNameBlur}
                              onChange={(event) => this.onChange("name", event.target.value)}
                            />
                          </div>
                          <div className="stripe_field expiry">
                            <label>Expiry Date</label>
                            <label>Required</label>
                            <CardExpiryElement options={cardExpiryOptions} onChange={this.onCardChange} />
                          </div>
                        </div>

                        <div className="stripe_field cvv">
                          <label className="short">CVV Number</label>
                          <label>Required</label>
                          <CardCvcElement options={cardCvvOptions} onChange={this.onCardChange} />
                        </div>
                        <div className="card_errors">
                          <div className="cardnumber"><div>!</div><span>Please enter in a valid credit card number</span></div>
                          <div className="name"><div>!</div><span>The name on this card is missing</span></div>
                          <div className="cardexpiry"><div>!</div><span>Please enter in a valid credit card expiry date</span></div>
                          <div className="cardcvc"><div>!</div><span>Please enter in a valid credit card CVC</span></div>
                        </div>
                      </React.Fragment>
                    )
                  }}
                </ElementsConsumer>
              </Elements>
            </div>
          </section>

          { this.state.isGift && <section className="billing">
            <div className="payment_header">Gift Recipient</div>
            { fields.filter(field => field.giftSection).map((field, key) => (
                <div key={key} className="payment_field">
                  <label>{`${field.label} ${field.required ? "*" : ""}`}</label>
                  <input
                    type="text"
                    ref={node => this[field.value] = node}
                    autoComplete={field.value === "giftConfirmEmail" ? "off" : "on"}
                    onChange={(event) => this.onChange(field.value, event.target.value)}
                  />
                  <div className="error-message">
                    <div>!</div><span>{`${field.label} is a required field`}</span></div>
                </div>
              ))
            }
          </section> }

          <section className="billing">
            <div className="payment_header">Billing Address</div>
            { fields.filter(field => field.display !== false && !field.giftSection).map((field, key) => (
                <div key={key} className="payment_field">
                  { field.value !== "email" && <label>{`${field.label} ${field.required ? "*" : ""}`}</label> }
                  { !field.getComponent &&
                    (field.value === "email" ?
                      // <input
                      //   type="text"
                      //   ref={node => this[field.value] = node}
                      //   value={userEmail}
                      //   disabled={true}
                      // />
                      <></>
                      :
                      <input
                        type="text"
                        ref={node => this[field.value] = node}
                        autoComplete={field.value === "confirmEmail" ? "off" : "on"}
                        onChange={(event) => this.onChange(field.value, event.target.value)}
                      />
                    )
                  }
                  { field.getComponent &&
                    this.getComponent(field.value)
                  }
                  <div className="error-message">
                    <div>!</div><span>{`${field.label} is a required field`}</span></div>
                </div>
              ))
            }
          </section>
        </CheckoutContainer>
        : null
      : <Redirect to="/cart" />
    )
  }
}

export default withStoreAndRouter()(Checkout);
