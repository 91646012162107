import React from "react";
import { withStoreAndRouter, apiFetch } from "../../utils";
import CartItem from "../../components/CartItem";
import CheckoutContainer from "../../components/CheckoutContainer";
import classnames from "classnames";
import moment from "moment-timezone";

class PopulatedCart extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      paymentIntent: null
    }
  }

  async componentDidMount() {
    const res = await apiFetch("/cart/start_checkout", { method: "GET" });
    const { update } = this.props;
    if(res.ok) {
      const json = await res.json();
      const paymentIntent = json.paymentIntent;
      this.setState({ paymentIntent });
      update({ events: json.userEvents });
    }
  }

  onSubmit = (checkoutState) => {
    const { history } = this.props;
    const { paymentIntent } = this.state;
    if (!checkoutState.cartErrors) {
      history.push("/checkout", {
        checkout: { ...checkoutState, paymentIntent, step: 2 }
      });
    }
  }

  render() {
    const { paymentIntent } = this.state;
    const { globals: { user: { cart , events, giftEvents} } }  = this.props;
    const isGift = cart.reduce((acc, item) => acc || item.isGift, false);

    cart.forEach(item => {
      item.giftPurchasedError = false;
      item.purchasedError = false;
      if (isGift) {
        if (Array.isArray(giftEvents) && (giftEvents.length > 0)) {
          cart.forEach(item => {
            if (giftEvents.find(e => e.id === item.id || e.videoId === item.id)) {
              item.giftPurchasedError = true;
            }
          });
        }
      } else {
        if (events.find(e => e.id === item.id || e.videoId === item.id)) {
          item.purchasedError = true;
        }
      }
    });
    const error = cart.reduce((acc, item) => {
      return {
        purchasedError: acc.purchasedError || item.purchasedError,
        giftPurchasedError: acc.giftPurchasedError || item.giftPurchasedError,
        expirationError: acc.expirationError || moment().isSameOrAfter(item.purchasableUntil)
      };
    }, {purchasedError: false, giftPurchasedError: false, expirationError: false});

    return (
      paymentIntent ?
      <CheckoutContainer className="Cart populated" header="Your Cart" step={1} onSubmit={this.onSubmit}>
        {/* <div className="message">Each event is available as a scheduled live event, and then as an on demand video for the following 48 hours.</div> */}
        <div className={classnames("message", "error-msg", {error: error.expirationError})}>One or more of the events in your cart have expired. Please remove expired events before continuing.</div>
        <div className={classnames("message", "error-msg", {error: error.purchasedError})}>You already have tickets to one or more events in your cart. Please remove from your cart before continuing.</div>
        <div className={classnames("message", "error-msg", {error: error.giftPurchasedError})}>This recipient has tickets to one or more events in your cart. Please remove from your cart before continuing.</div>
        { cart.map(item => <CartItem key={item.id} {...item} />) }
      </CheckoutContainer>
      : null
    )
  }
}

export default withStoreAndRouter()(PopulatedCart);
